import {bookingConstants} from '../constants';
import {booking} from "../services/booking";
import Moment from "moment";

export const bookingActions = {
    getBookedDays,
    book,
    clear,
};

function getBookedDays(from) {

    const timeZoneOffset = new Date().getTimezoneOffset();
    const fromUtc = from ? Moment(from).subtract(timeZoneOffset, "minutes").toDate() : null

    return dispatch => {
        dispatch(request());
        booking.getBookedDays(fromUtc)
            .then(days => {
                const timeZoneOffset = new Date().getTimezoneOffset();
                return days.map(day => Moment(day).add(timeZoneOffset, "minutes").toDate());
            })
            .then(data => dispatch(success(data)))
            .catch(error => dispatch(failure(error)));
    };

    function request() {
        return {type: bookingConstants.BOOKING_GET_DAYS_REQUEST}
    }

    function success(unavailableDays) {
        return {type: bookingConstants.BOOKING_GET_DAYS_SUCCESS, unavailableDays}
    }

    function failure(error) {
        return {type: bookingConstants.BOOKING_GET_DAYS_FAILURE, error}
    }
}

function book(reservation) {

    const timeZoneOffset = new Date().getTimezoneOffset();
    const reservationUtcTime = {
        ...reservation,
        checkInAt: Moment(reservation.checkInAt).subtract(timeZoneOffset, "minutes").toDate(),
        checkOutAt: Moment(reservation.checkOutAt).subtract(timeZoneOffset, "minutes").toDate(),
    }

    return dispatch => {
        dispatch(request());

        booking.book(reservationUtcTime)
            .then(data => dispatch(success()))
            .catch(error => dispatch(failure(error)));
    };

    function request() {
        return {type: bookingConstants.BOOKING_BOOK_REQUEST}
    }

    function success() {
        return {type: bookingConstants.BOOKING_BOOK_SUCCESS}
    }

    function failure(error) {
        return {type: bookingConstants.BOOKING_BOOK_FAILURE, error}
    }
}

function clear() {
    return dispatch => dispatch({type: bookingConstants.BOOKING_CLEAR});
}
