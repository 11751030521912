import config from '../config.json';
import {handleResponse, queryParams} from '../helpers';

export const booking = {
    getBookedDays,
    book,
};

function getBookedDays(from) {
    const requestOptions = {
        method: 'GET',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
    };
    const params = queryParams({
        'from': from?.format(),
    });

    return fetch(`${config.apiUrl}/v1/reservations/days?${params}`, requestOptions)
        .then(handleResponse)
}

function book(reservation) {
    const requestOptions = {
        method: 'PUT',
        mode: 'cors',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(reservation)
    };

    return fetch(`${config.apiUrl}/v1/reservations`, requestOptions)
        .then(handleResponse);
}