import React from 'react';
import {Link} from 'react-router-dom';

class Features extends React.Component {
    render() {
        return (
            <section id="pricing">
                <div className="pricing-cards">
                    <div className="row">
                        <div className="col-lg-4 col-sm-6 pricing-card">
                            <h2>Sezon niski</h2>
                            <p className="pricing-info">Październik - Kwiecień</p>
                            <p className="pricing-price">400&nbsp;zł</p>
                            <p className="pricing-info">/doba 2 osoby</p>
                        </div>
                        <div className="col-lg-4 col-sm-6 pricing-card">
                            <h2>Sezon wysoki</h2>
                            <p className="pricing-info">Maj - Wrzesień</p>
                            <p className="pricing-price">450&nbsp;zł</p>
                            <p className="pricing-info">/doba 2 osoby</p>
                        </div>
                        <div className="col-lg-4 col-sm-6 pricing-card last">
                            <h2>Dopłaty</h2>
                            <div className="pricing-text">
                                <p className="p1">
                                    Dzieci do 7 lat
                                    <span className="pricing-info2"> -&nbsp;bezpłatnie</span>
                                </p>
                            </div>
                            <div className="pricing-text">
                                <p className="p1">
                                    Dzieci od 7 lat i&nbsp;dorośli
                                    <span className="pricing-info2"> -&nbsp;50&nbsp;zł/doba</span>
                                </p>
                            </div>
                            <div className="pricing-text">
                                <p className="p1">
                                    Zwierzaki
                                    <span className="pricing-info2"> -&nbsp;50&nbsp;zł/pobyt</span>
                                </p>
                            </div>
                            <div className="pricing-text">
                                <p className="p1">
                                    Kaucja zwrotna
                                    <span className="pricing-info2"> -&nbsp;500&nbsp;zł</span>
                                </p>
                                <p className="p4 pricing-info">
                                    Jesteśmy dogfriendly, ale z braku ogrodzenia działki i troski o bezpieczeństwo na jakiś czas nie mozemy ich przyjmować.
                                </p>
                            </div>
                        </div>
                        <div className="col-lg-12 col-sm-6 pricing-hints">
                            <div className="row">
                                <div className="col-lg-4 col-sm-12">
                                    <div className="pricing-hint hint-1">
                                        Minimalny pobyt<br/>
                                        w Ostoi to 2 noce
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="pricing-hint hint-2">
                                        Święta i długie weekndy<br/>
                                        wyceniamy indywidualnie
                                    </div>
                                </div>
                                <div className="col-lg-4 col-sm-12">
                                    <div className="pricing-hint hint-3">
                                        Zapoznaj się<br/>
                                        z <a href="/terms-and-conditions">Regulaminem Wynajmu</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div id="booking">
                    <h1 className="booking-title">Czas zaczerpnąć<br/>powietrza<br/><i>Czekamy!</i></h1>
                    <p className="booking-desc d-none d-md-block">
                        Bliskość natury, malownicze widoki i otwarte przestrzenie - to tylko kilka<br/>
                        argumentów, z powodu których nie będziesz chciał wracać do miasta.<br/>
                        Ostrzegaliśmy!
                    </p>
                    <p className="booking-desc d-md-none">
                        Bliskość natury, malownicze widoki i&nbsp;otwarte przestrzenie -&nbsp;to&nbsp;tylko kilka
                        argumentów, z&nbsp;powodu których nie&nbsp;będziesz chciał wracać do&nbsp;miasta.<br/>
                        Ostrzegaliśmy!
                    </p>
                    <Link to="/book/1" className="btn btn-support1 responsive">Zarezerwuj domek</Link>
                </div>
            </section>
        );
    }
}

export default Features;
